@tailwind components;

@layer components {
  /* large */
  .landing-page {
    @apply bg-center bg-cover bg-no-repeat flex flex-col;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.44) -9.21%, rgba(0, 0, 0, 0.38) 100%),
      url('../../assets/images/landing-page-background.png');
    min-height: calc(100vh - 104px);
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .landing-page-heading {
    font-size: 2.8rem;
  }

  .landing-page-div {
    @apply px-5 h-[87vh];
  }

  .landing-page-container-div {
    @apply pb-14 h-[100%] from-slate-400;
  }

  .landing-search-container {
    display: flex;
    width: 100%;
  }

  .landing-search-inputs {
    display: flex;
  }

  .landing-date-filter {
    display: flex;
  }

  .landing-search-btn {
    width: 100%;
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
  }

  .landing-search-item {
    /* padding-top: 1rem;
    padding-bottom: 1rem; */
  }

  .landing-filter-modal {
    min-width: 17rem;
  }

  /* .landing-filter-modal p {
    max-width: 60vw; // Not sure if we need this coz this is breaking the max-w on Available Date min max pills.
  } */

  .landing-date-input {
    min-width: 13rem;
  }

  .landing-search-input {
    width: 100%;
  }

  .landing-date-input .datepicker-wrapper {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .landing-search-input .search-input-wrapper {
    padding-left: 1.125rem;
  }

  .landing-search-container .search-input-pill p {
    max-width: 22vw;
  }

  /*  medium - iPAD */
  @media only screen and (max-width: 1023px) {
    .landing-page {
      min-height: calc(100vh - 52px - 5.5rem);
      padding-left: 2%;
      padding-right: 2%;
    }

    .landing-page-heading {
      padding-top: 7rem;
      padding-bottom: 1.4rem;
      font-size: 2.2rem;
      text-align: center;
    }

    /* remove flex from search */
    .landing-search-container {
      display: block;
    }

    /* remove flex from search inputs  */
    .landing-search-inputs {
      display: block;
    }

    .landing-search-container .search-input-pill p {
      max-width: 53vw;
    }

    /*   .landing-search-input .search-input{
      min-width:20rem;
    } */

    .landing-filter-modal {
      min-width: 14rem;
    }

    .landing-date-filter {
      justify-content: space-between;
    }
    .landing-date-filter .landing-search-item {
      width: 48.6%;
    }
    .landing-search-inputs .search-input-wrapper {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    /* .landing-search-input, */
    .landing-date-input {
      padding-right: 0;
    }
    /* .landing-search-input, */
    .landing-search-item,
    .landing-search-btn {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    .landing-page {
      min-height: calc(100vh - 52px - 6rem);
    }

    .landing-search-container .search-input-pill p {
      max-width: 65vw;
    }

    .landing-page-heading {
      padding-top: 6.6rem;
      padding-bottom: 2rem;
      font-size: 2.1rem;
      text-align: center;
      line-height: 2.8rem;
    }
  }

  /* mobile transition */
  @media only screen and (max-width: 700px) {
    /* remove flex from all search inputs  */
    .landing-search-inputs {
      display: block;
    }
    .landing-search-container {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .landing-date-filter {
      justify-content: space-between;
    }
    .landing-search-inputs .search-input-wrapper {
      width: 100%;
    }
    /* .landing-search-input, */
    .landing-date-input {
      padding-right: 0;
    }
  }

  /* Mobile on landscape mode */
  @media only screen and (max-width: 1023px) and (max-height: 499px) {
    .landing-page-heading {
      padding-top: 10%;
    }
  }

  /* mobile */
  @media only screen and (max-width: 499px) {
    .landing-page {
      min-height: calc(100vh - 52px - 6rem);
      display: flex;
      padding-top: 20%;
    }

    .landing-date-filter {
      display: block;
    }
    .landing-date-filter .landing-search-item {
      width: 100%;
    }
    .landing-page-heading {
      padding-top: 0rem;
      padding-left: 16%;
      padding-right: 16%;
    }
    .landing-search-inputs .search-input-wrapper {
      width: 100%;
    }
    .landing-date-input .datepicker-wrapper {
      padding-left: 1.125rem;
      padding-right: 1.125rem;
    }
  }

  @media only screen and (max-width: 399px) {
    .landing-page-heading {
      padding-left: 11%;
      padding-right: 11%;
    }
    .landing-search-container .search-input-pill p {
      max-width: 60vw;
    }
  }

  @media only screen and (max-width: 350px) {
    .landing-page-heading {
      padding-left: 4%;
      padding-right: 4%;
    }
    .landing-search-container .search-input-pill p {
      max-width: 58vw;
    }
  }

  @media only screen and (max-width: 310px) {
    .landing-page-heading {
      padding-left: 0;
      padding-right: 0;
    }
    .landing-search-container .search-input-pill p {
      max-width: 65vw;
    }
  }

  /* gutter transitions */
  @media only screen and (min-width: 580px) {
    .landing-page {
      padding-left: 8%;
      padding-right: 8%;
    }
  }
  @media only screen and (min-width: 680px) {
    .landing-page {
      padding-left: 12%;
      padding-right: 12%;
    }
  }

  @media only screen and (min-width: 750px) {
    .landing-page {
      padding-left: 16%;
      padding-right: 16%;
    }
  }

  @media only screen and (min-width: 810px) {
    .landing-page {
      padding-left: 18%;
      padding-right: 18%;
    }
  }

  @media only screen and (min-width: 1024px) {
    .landing-page {
      padding-left: 2%;
      padding-right: 2%;
    }
    .landing-page-gutter {
      margin: 0 auto;
    }
    .landing-page-heading {
      padding-top: 10rem;
      padding-bottom: 2rem;
    }
    .landing-search-input,
    .landing-date-input,
    .landing-filter-modal {
      padding-right: 1rem;
    }
    .landing-search-input {
      min-width: 20rem;
    }
    .landing-search-btn {
      /* height: 3.5rem; */
      min-width: 9rem;
    }
  }

  @media only screen and (min-width: 1100px) and (max-width: 1199px) {
    .landing-search-container .search-input-pill p {
      max-width: 20vw;
    }
  }
  @media only screen and (min-width: 1680px) and (max-width: 1947px) {
    .landing-search-container .search-input-pill p {
      max-width: 19vw;
    }
  }
  @media only screen and (min-width: 1948px) and (max-width: 2560px) {
    .landing-search-container .search-input-pill p {
      max-width: 14vw;
    }
  }

  @media only screen and (min-width: 1200px) {
    .landing-search-input {
      min-width: 26rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .landing-search-input {
      min-width: 28rem;
    }
  }
}
