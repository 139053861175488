@tailwind components;

@layer components {
  .map-page {
    @apply h-[calc(100vh-3.25rem)];
  }

  .map-page-external {
    @apply h-[calc(100vh-5.6rem)];
  }

  .map-view-list button {
    width: 100%;
  }

  .map-view-list button svg {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .map-toolbar,
  .map-toolbar-external {
    position: fixed;
    top: 28%;
    z-index: 10;
  }

  .map-section {
    @apply w-[calc(100%-36rem)];
  }

  input.radius-input::-webkit-outer-spin-button,
  input.radius-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'].radius-input {
    -moz-appearance: textfield;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .map-section {
      @apply w-[60%];
    }

    .map-section > .mapboxgl-map > .mapboxgl-canvas-container > .mapboxgl-canvas {
      @apply !w-[100%];
    }
  }

  @media only screen and (max-width: 1023px) {
    .map-section {
      @apply w-[100vw];
    }
    .map-view-list {
      -webkit-box-align: center;
      align-items: center;
      position: fixed;
      z-index: 29;
    }
  }

  @media only screen and (max-width: 1023px) {
    .map-page {
      @apply h-[calc(100vh-52px)];
    }

    .map-page-external {
      @apply h-[calc(100vh-54px)];
    }
  }

  @media only screen and (max-width: 1023px) {
    .map-toolbar,
    .map-toolbar-external {
      top: 11rem;
    }
  }

  @media only screen and (max-width: 767px) {
    .map-page,
    .map-page-external {
      @apply h-[calc(100vh-52px)];
    }

    .map-toolbar {
      top: 10rem;
    }
    .map-toolbar-external {
      top: 15rem;
    }
  }

  @media (min-width: 0px) {
    .map-view-list {
      inset: auto auto 10px 12px;
      width: calc(100% - 24px);
    }
  }
}
